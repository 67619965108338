import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Website",
  "time": "stuff",
  "index": 4,
  "type": "project",
  "hidden": false,
  "splash": "A landing page to showcase my projects & experience"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GitLink = makeShortcode("GitLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1><a parentName="h1" {...{
        "href": "https://website.davidpeet.me"
      }}>{`This Website`}</a></h1>
    <p><strong parentName="p">{`Date Range:`}</strong>{` 2019 (Updated 2021)`}</p>
    <p><strong parentName="p">{`Key Technologies:`}</strong>{` `}<inlineCode parentName="p">{`React`}</inlineCode>{` `}<inlineCode parentName="p">{`Gatsby`}</inlineCode></p>
    <p><strong parentName="p">{`Github Source:`}</strong>{` `}<GitLink url="https://github.com/DavidPeet8/website" mdxType="GitLink" /></p>
    <hr></hr>
    <p>{`Seems self explanatory... You're looking at it! 😄`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      